/*! Agile Pixel https://agilepixel.io - 2021*/
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const getOffset = function (element) {
  let _x = 0;
  let _y = 0;
  while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
    _x += element.offsetLeft - element.scrollLeft;
    _y += element.offsetTop - element.scrollTop;
    element = element.offsetParent;
  }
  return {
    top: _y,
    left: _x,
  };
};

const scrollCheck = function (event) {
  const $offset = 0;
  const link = event.currentTarget;
  if (!link && typeof link.getAttribute === 'function') {
    return;
  }
  let hash = link.getAttribute('href');
  hash = hash.replace(/^\//, '');
  const element = document.querySelector(hash);

  if (element) {
    const top = getOffset(element).top - $offset;

    window.scrollTo({
      top,
      behavior: 'smooth',
    });

    history.pushState({}, false, hash);
  } else {
    location.reload();
  }
};

window.addEventListener('hashchange', scrollCheck);

window.addEventListener('popstate', scrollCheck);

const smoothLink = document.querySelectorAll('.js-menu-smoothscroll a');
const autoSmoothLinks = document.querySelectorAll('a[href*="#"]');
const isHome = document.querySelector('.home');
if (isHome) {
  for (const element of smoothLink) {
    element.addEventListener('click', (event) => {
      event.preventDefault();

      scrollCheck(event);
    });
  }

  for (const element of autoSmoothLinks) {
    element.addEventListener('click', (event) => {
      event.preventDefault();

      scrollCheck(event);
    });
  }
}
